var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","items":_vm.gebruikers,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Gebruikers")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Zoek in grid"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.hasUserAdmin)?_c('div',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g({attrs:{"medium":""},on:{"click":_vm.openNewUserDialog}},on),[_vm._v("mdi-plus-circle")])],1)],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.itemModel.email))])]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"disabled":_vm.itemModel.id > 0,"rules":[_vm.rules.required],"label":"Email","required":"","autofocus":""},model:{value:(_vm.itemModel.email),callback:function ($$v) {_vm.$set(_vm.itemModel, "email", $$v)},expression:"itemModel.email"}}),_c('v-checkbox',{attrs:{"label":"Kan audits afnemen"},model:{value:(_vm.itemModel.hasAudit),callback:function ($$v) {_vm.$set(_vm.itemModel, "hasAudit", $$v)},expression:"itemModel.hasAudit"}}),(_vm.hasUserAdmin)?_c('v-checkbox',{attrs:{"label":"Admin rechten"},model:{value:(_vm.itemModel.hasUserAdmin),callback:function ($$v) {_vm.$set(_vm.itemModel, "hasUserAdmin", $$v)},expression:"itemModel.hasUserAdmin"}}):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Annuleren")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":!_vm.valid},on:{"click":_vm.updateUser}},[_vm._v("Opslaan")])],1),_c('v-container',_vm._l((_vm.errs),function(err,index){return _c('v-alert',{key:index,attrs:{"outlined":"","type":"error"}},[_vm._v(" "+_vm._s(err.msg))])}),1)],1)],1)],1)]},proxy:true},{key:"item.datum_registratie",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.datum_registratie))+" ")]}},{key:"item.aantal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.aantal)+" ")]}},{key:"item.hasAudit",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.hasAudit),callback:function ($$v) {_vm.$set(item, "hasAudit", $$v)},expression:"item.hasAudit"}})]}},{key:"item.hasUserAdmin",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.hasUserAdmin),callback:function ($$v) {_vm.$set(item, "hasUserAdmin", $$v)},expression:"item.hasUserAdmin"}})]}},{key:"item.aanpassen",fn:function(ref){
var item = ref.item;
return [(_vm.hasUserAdmin)?_c('div',{on:{"click":function($event){return _vm.openUpdateUserDialog(item)}}}):_vm._e(),_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.openUpdateUserDialog(item)}}},[_vm._v("mdi-pencil-outline")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }