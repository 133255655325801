<template>
  <v-container>


    <v-data-table :headers="headers" dense :items="gebruikers" :search="search">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Gebruikers</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Zoek in grid" class="mx-4"></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <div v-if="hasUserAdmin">
                <v-btn icon>
                  <v-icon v-on="on" medium @click="openNewUserDialog">mdi-plus-circle</v-icon>
                </v-btn>
              </div>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">{{ itemModel.email }}</span>
              </v-card-title>
              <v-card-text>
                <v-form @submit.prevent="" v-model="valid">
                  <v-text-field :disabled="itemModel.id > 0" v-model="itemModel.email" :rules="[rules.required]"
                    label="Email" required autofocus></v-text-field>
                  <v-checkbox v-model="itemModel.hasAudit" label="Kan audits afnemen"></v-checkbox>
                  <v-checkbox v-if="hasUserAdmin" v-model="itemModel.hasUserAdmin" label="Admin rechten"></v-checkbox>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Annuleren</v-btn>
                <v-btn color="blue darken-1" :disabled="!valid" @click="updateUser">Opslaan</v-btn>
              </v-card-actions>
              <v-container>
                <v-alert outlined type="error" v-for="(err, index) in errs" :key="index">
                  {{ err.msg }}</v-alert>
              </v-container>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.datum_registratie`]="{ item }">
        {{ formatDate(item.datum_registratie) }}
      </template>

      <template v-slot:[`item.aantal`]="{ item }">
        {{ item.aantal }}
      </template>

      <template v-slot:[`item.hasAudit`]="{ item }">
        <v-simple-checkbox v-model="item.hasAudit" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.hasUserAdmin`]="{ item }">
        <v-simple-checkbox v-model="item.hasUserAdmin" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.aanpassen`]="{ item }">
        <div v-if="hasUserAdmin" @click="openUpdateUserDialog(item)"></div>
        <v-icon medium @click="openUpdateUserDialog(item)">mdi-pencil-outline</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import client from "api-client";
import { mapState } from "vuex";
import { format } from "date-fns";

export default {
  name: "AdminGebruikers",
  data: () => ({
    valid: false,
    itemModel: {},
    errs: [],
    dialog: false,
    gebruikers: [],
    search: "",
    rules: {
      required: (value) => !!value || "Verplicht.",
    },
    headers: [
      { text: "Email", value: "userName", sortable: true },
      { text: "Geregistreerd", value: "datum_registratie" },
      { text: "Aantal Deelnames", value: "aantal" },
      { text: "Audit afnemen", value: "hasAudit" },
      { text: "Admin", value: "hasUserAdmin" },
      { text: "", value: "aanpassen", align: 'end' },
    ],
  }),
  methods: {
    filterGrid(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    openNewUserDialog() {
      this.itemModel = {}
      this.errs = []
      this.dialog = true
    },
    openUpdateUserDialog(item) {
      this.itemModel = { email: item.userName, ...item };
      this.errs = []
      this.dialog = true
    },
    updateUser() {
      if (this.itemModel.id) {
        client.updateGebruiker(this.$route.params.instantieId, {
          ...this.itemModel
        }).then(() => {
          this.getUsers()
          this.dialog = false
        }).catch((errs) => {
          this.errs = errs
        })
      } else {
        client.addGebruiker(this.$route.params.instantieId, {
          ...this.itemModel
        }).then(() => {
          this.getUsers()
          this.dialog = false
        }).catch((errs) => {
          this.errs = errs
        })
      }
    },
    formatDate(date) {
      if (date != null) return format(new Date(date), "dd-MM-yyyy");
    },
    getUsers() {
      client.fetchAdminGebruikers(this.$route.params.instantieId).then((data) => {
        this.gebruikers = data;
      });
    },
    verwijderGebruiker() {
      if (this.itemModel.id != null) {
        client
          .verwijderLijst({
            id: this.itemModel.id,
          })
          .then(() => {
            this.getUsers();
            this.dialog = false;
          });
      }
    },
    onAanpassen(item) {
      this.itemModel = { ...item }.email = item.userName;
      console.log(this.itemModel);
      this.dialog = true;
    },
  },
  computed: {
    ...mapState({
      gebruiker: "gebruiker",
    }),
    instantie: function () {
      return this.$store.getters.getInstantie(this.$route.params.instantieId)
    },
    hasAudit: function () {
      return (this.instantie?.hasAudit || this.gebruiker.has_admin)
    },
    hasUserAdmin: function () {
      return (this.instantie?.hasUserAdmin || this.gebruiker.has_admin)
    }
  },
  mounted() {
    this.getUsers();
  },
};
</script>